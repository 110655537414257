import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class ExcelDoc extends React.Component {
    render() {

        const dataSets = this.props.data;

        function exCol (lbl, i) {return <ExcelColumn key={i} label={lbl} value={lbl} />};

        function exSheet(sheetName, data){
            if (!data) return null;
            const labels = data.length>0 ? Object.keys(data[0]) : [];

            return (
                <ExcelSheet data={data} name={sheetName}>
                    {
                    labels.map((l, i)=>exCol(l, i))
                    }
                </ExcelSheet>

            );

        }

        return (
            <ExcelFile element={<div>Excel</div>}>
                {Object.keys(dataSets).map(k=> exSheet(k, dataSets[k]))}
            </ExcelFile>
        );
    }
}

export default ExcelDoc;