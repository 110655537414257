import React from "react";
import './CallReportContainer.css';
import IMG_ACC from '../../images/dashboard/accIcon.png';
import IMG_ASS from '../../images/dashboard/assisIcon.png';
import IMG_ATT from '../../images/dashboard/attenIcon.png';
import IMG_CALL from '../../images/dashboard/callIcon.png';
import IMG_EMG from '../../images/dashboard/emergency.png';
import IMG_ALL from '../../images/dashboard/allIcon.png';


const CallReportContainer = (props) => {

    const colours = {
      Call: "rgb(245, 129, 78)",
      Emergency : "red",
      Assistance : "rgb(248, 218, 60)",
      Attendance : "rgb(148, 202, 102)",
      Accessory : "purple"    
    }
  
    let headerStyle = {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      padding: "0px",
      backgroundColor: props.color ? props.color : colours[props.callType],
      width:  "100%",
      position:"relative"
    }

    const containerHeaderStyle = {
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      verticalAlign: "center",
      color: "white",
    }

    const reportContainer = {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      padding: "10px",
      color: "white",
      border: "1px",
      borderStyle:"solid",
      backgroundColor: props.backgroundColor ? props.backgroundColor : "white",
      paddingBottom: "10px",
      borderColor: props.color ? props.color : colours[props.callType],
      zIndex:"0"
    }

    let contStyle = {
      minWidth: "65%",
      width:(props.fullWidth===true) ? "100%" : ""
    };

    let IMG = GetCallImage(props.callType);
    return (
            <div style={contStyle}>
               <div style={headerStyle}>
                <div style={containerHeaderStyle} >
                  <img alt="Card image" height="30" width={(props.callType=="Assistance") ? "25" : "30"} src={IMG} style={{ padding: "5px", marginLeft: "5px", alignSelf: "center"}} />
                  <div style={{marginLeft:"1em", marginRight:"1em", width:"100%", alignSelf: "center"}}>{props.title ? props.title : props.callType}</div>
                </div>  
              </div>
              <div style={reportContainer}>{props.children}</div>
            </div>
    );
}


const GetCallImage = (strType) => {
  switch (strType) {
    case 'Emergency':
      return IMG_EMG;
    case 'Assistance':
      return IMG_ASS;
    case 'Accessory':
      return IMG_ACC;
    case 'Call':
      return IMG_CALL;
    case 'Attendance':
      return IMG_ATT;
    case 'Total Calls':
        return IMG_ALL;
  }
}

export default CallReportContainer;
