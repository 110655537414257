import React, {useState, useEffect, useContext} from 'react';
import Moment, { utc } from 'moment';
import './Dashboard.css';
import firebase from 'firebase';
import DashboardSummaryContainer from './DashSummaryContainer';
import 'bootstrap/dist/css/bootstrap.min.css'
import cleanData from '../../components/ADCChartSelector/CleanData';
import getLocalSetting from '../../components/get_local_setting';
import { getAverageTime} from '../../components/common/time_helper';
import { getDBSetting } from '../../v2/localValues';
import { getTime } from 'date-fns';

const CompletedCalls = (props) => {
    const dbVal = getDBSetting();
    const [panelCalls, setPanelCalls] = useState({});
    const [calls, setCalls] = useState([]);
    const [callListeners, setCallListeners] = useState([]);
    const [displayTitle, setDisplayTitle] = useState("");
    const [siteConfig, setSiteConfig] = useState([]);
    const EmergencyCalls = fieldFilter(calls, 'Emergency', "Call Type");
    const CallCalls = fieldFilter(calls, 'Call', "Call Type");
    const AccessoryCalls = fieldFilter(calls, 'Accessory', "Call Type");
    const AttendanceCalls = fieldFilter(calls, 'Attendance', "Call Type");
    const CareCalls = fieldFilter(calls, 'CareCall', "Call Type");
    const AssistanceCalls = fieldFilter(calls, 'Assistance', "Call Type");
    const userList = JSON.parse(getLocalSetting("uMails"));
    const dbPath = localStorage.getItem("dbPath");
    const hasSense = hasSensePermission();


    function hasSensePermission(){
        try {
            return (props.USER.permissions.filter((p)=>p.permission==="sense")[0].granted===1);
        }
        catch(e){ 
            console.log(e);
            return false; }
        
    }


    useEffect(()=>{

        if (calls.length > 0) {
            setCalls([]);
            setPanelCalls({});
        }

        let selectionsList = props.homeSelection;

        selectionsList.map(s=>{
            getCallData(s.value, props.dateSelection, s.label);  
        });
      
    },[props.dateSelection, props.homeSelection]);

    useEffect(()=>{
        if (props.printCallBack) props.printCallBack(printObj, excelPrint, printObj);
    }, [calls]);

    const getDateRef = (ref) => {
        let options = getDateSelectionList();
        let n = "";
        options.map((o)=>{
            if (o.label === ref){
                n=o.value
            }
        });
        let strDate = Moment(n).format('DDMMYYYY');
        return strDate;
    }

    const mapEmailAddress=(email, userList)=>{
        if (!Array.isArray(userList)) return email;
        userList.map(u=>{
            if (u.email === email) return u.first_name + " " + u.surname;
        });
        return email;
    }

    function getCallKeyName(house, cTime, room){
        return (house + cTime + room).toLowerCase();
    }

    function getTimeFromLongDate(longDate){ 
        console.log("longdata", longDate);      
        if (longDate===undefined) return "";
        if (longDate.length>10) {
            longDate = longDate.split(" ")[1];
            longDate = longDate.split(".")[0];
        }
        return longDate;
    }

    function addAnHour(tm){
        const tSplit = tm.split(":");
        return tm;
    }
    
    function getStartDate(rec){
        return rec.start;//(rec.startFullDate!==undefined) ? rec.startFullDate : rec.start;
    }

    function getStartTime(rec){
        //let eDate = getEndTime(rec);
        let sDate = getTimeFromLongDate(rec.start);
        //if (sDate > eDate) return addAnHour(sDate); 
        return sDate;
    }

    function getEndDate(rec){
        return (rec.end_date_time!==undefined) ? rec.end_date_time : rec.end;
    }


    //function getTimeFromDate(dt) { return getTimeFromLongDate(dt) };


    function getEndTime(rec){
        return rec.end;
    }

    function getField(obj, fieldName){
        try { return obj[fieldName]; }
        catch(e){ return ""; }
    }

    function calculateDurationFromUTC(start, end){
        console.log("start", start);
        console.log("end", end);
        const startD = Moment(start).startOf("seconds");
        const endD = Moment(end).endOf("seconds");
        const duration = Moment(endD).diff(Moment(startD), 'seconds');
        return duration;
    }


    function checkIfUTCDateAndConvert(dtTime){
        if (dtTime.length>10) return dtTime;
        return createUTCDateFromTime(dtTime);
    }

    function createUTCDateFromTime(time){
        const d = new Date();
        const dStr = d.toISOString().split("T")[0];
        return dStr + " " + time;
    }

    function switchStartEnd(start, end){
        //if (start > end) return [end, start];
        return [start, end];
    }

    function prepareDate(val){
        return val.replace(" ", "T");
    }

    function yymmddToMoment(dateString) {
        return Moment(dateString, "YYYY/MM/DD HH:mm:ss");
    }

    function utcToLocalTime(utcDateString) {

        if (props.dateFormat==="f3"){
            // Create a Date object from the UTC date string
            const utcDate = new Date(utcDateString);
            utcDate.setHours(utcDate.getHours()-1);
            // Get the local time components
            const localHours = ('0' + utcDate.getHours()).slice(-2);
            const localMinutes = ('0' + utcDate.getMinutes()).slice(-2);
            const localSeconds = ('0' + utcDate.getSeconds()).slice(-2);
        
            // Format the local time as "hh:mm:ss"
            const localTime = `${localHours}:${localMinutes}:${localSeconds}`;
            return localTime;
                
        }


        // Create a Date object from the UTC date string
        const utcDate = new Date(utcDateString);
        
        // Get the local time components
        const localHours = ('0' + utcDate.getHours()).slice(-2);
        const localMinutes = ('0' + utcDate.getMinutes()).slice(-2);
        const localSeconds = ('0' + utcDate.getSeconds()).slice(-2);
    
        // Format the local time as "hh:mm:ss"
        const localTime = `${localHours}:${localMinutes}:${localSeconds}`;
        return localTime;
    }

    function dateToTime(dateString) {
        console.log("dateToTime",dateString);
//        dateString = dateString.replace(" ", "T")
        const dd = new Date(dateString);
        console.log("converted", dd);
        const hours = ('0' + dd.getUTCHours()).slice(-2);
        const minutes = ('0' + dd.getUTCMinutes()).slice(-2);
        const seconds = ('0' + dd.getUTCSeconds()).slice(-2);
        return `${hours}:${minutes}:${seconds}`;
    }
/*
    function yymmddToMomentUTCTime(dateString) {
        const dd = new Date(dateString);
        return `${dd.getHours()}:${dd.getMinutes()}:${dd.seconds()}`
        console.log("dateString", dateString);
        console.log(dd);
        return dd;
        return Moment.utc(dateString, "YYYY/MM/DD HH:mm:ss").format("HH:mm:ss");
    }
*/

function addTimeToDate(date, timeString) {
    console.log("timeString",timeString)
    // Extract hours, minutes, and seconds from the time string
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Clone the original date to avoid modifying it directly
    const newDate = new Date(date);

    // Add the time components to the cloned date
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(seconds);

    return newDate;
}
    function getStartAndEnd(data, formatDate){
        if (formatDate==="f1") {
            console.log("f1"); 
            return [data["start"], data["end_date_time"]];
        }
        if (formatDate==="f2") {
            console.log("f1"); 
            return [data["start"], addTimeToDate(Date(), data["end"])];
        }
        console.log("default");
        return switchStartEnd(checkIfUTCDateAndConvert(getStartTime(data)), checkIfUTCDateAndConvert(getEndTime(data)));
        return switchStartEnd(checkIfUTCDateAndConvert(getStartTime(data)), checkIfUTCDateAndConvert(getEndTime(data)));
    }

    function addCallSnapshot(snapshot, house, rDate, houseTitle){
        console.log("SNAPPPPP", snapshot.val());
            let tcalls = [];//calls;

            snapshot.forEach(snap => {
                const r = snap.val();

                const [startD, endD] = getStartAndEnd(r, props.dateFormat);
                if (r.callType==="Visit") return;
                if (hasSense === true) {
                    
                    tcalls.push(
                        {
                            "House": houseTitle,
                            "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                            "Zone" : r.zone,
                            "Resident" : "",
                            "Call Point" : r.room,
                            "Call Type" : (r.callType==="Sense")?"Accessory":r.callType,
                            "Sense": getField(r,"AccessoryType"),
                            "Start Time" : utcToLocalTime(startD),
                            "End Time" : utcToLocalTime(endD),
                            "Call Duration" : calculateDurationFromUTC(startD, endD).toString(),//r.duration,
                            "Carer ID" : r.carer,
                            "Care Delivered" : r["Care Delivered"]  
                        }
                    );
                }
                else {

                    tcalls.push(
                        {
                            "House": houseTitle,
                            "Date" : getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                            "Zone" : r.zone,
                            "Resident" : "",
                            "Call Point" : r.room,
                            "Call Type" : r.callType,
                            "Start Time" : utcToLocalTime(startD),
                            "End Time" : utcToLocalTime(endD),
                            "Call Duration" : calculateDurationFromUTC(startD, endD).toString(),//r.duration,
                            "Carer ID" : r.carer,
                            "Care Delivered" : r["Care Delivered"]  
                        }
                    );
                }
                
            });

            
            tcalls = tcalls.filter(c=>c["End Time"]!==null);                            //remove null ends
            tcalls = tcalls.filter(c=>c["End Time"]!=="");                              //remove blank ends 
            tcalls = tcalls.filter(c=>c["End Time"]!=="Live");
            tcalls = tcalls.filter(c=>typeof(c["Call Point"])==="string");      //remove if room is undefined
            
            tcalls = tcalls.map(c =>
                ((c["Carer ID"]) && (c["Carer ID"]!=="") && (c["Carer ID"]!=="Room Unit")) 
                ? { ...c, "Carer ID": mapEmailAddress(c["Carer ID"], userList) }
                : c
            );
            //tcalls = tcalls.reverse();
            tcalls = cleanData(tcalls, siteConfig);
            console.log({tcalls});
            let panelCallsObj = {...panelCalls};

            if (panelCallsObj.hasOwnProperty(house)) {
                panelCallsObj[house] = tcalls;
            }
            else {
                Object.assign(panelCalls, {[house]: tcalls});                
            }
            
            setPanelCalls(panelCallsObj);
            panelCallsToCalls();

    }

    const getfb = (house, rDate, houseTitle, ref)=> firebase.apps[1].database(dbPath).ref(ref).on("value", snapshot => addCallSnapshot(snapshot, house, rDate, houseTitle));

    function getCallData (house, rDate, houseTitle) {
        let ref = house + "/" + getDateRef(rDate);
        callListeners.push(getfb(house, rDate, houseTitle, ref));          
    }

    function panelCallsToCalls(){
        
        let tcalls = [];
        let homeSelection = props.homeSelection ? props.homeSelection : [];
        if (panelCalls==null) return;
        Object.keys(panelCalls).map((k)=>{
            if (homeSelection.filter((h)=>h.value==k).length>0)tcalls.push(...panelCalls[k]);
        });

        tcalls = orderCallsByDateASC(tcalls);
        tcalls = tcalls.reverse();
        setCalls(tcalls);

    }
    
    function orderCallsByDateASC(calls){
        return calls.sort(function(a,b){return (a["Start Time"] > b["Start Time"]) ? 1 : -1});
    }

    const avAllCalls = getAverageTime(calls, "Call Duration");
    const avEmgCalls = getAverageTime(EmergencyCalls, "Call Duration");
    const avAccCalls = getAverageTime(AccessoryCalls, "Call Duration");
    const avAssCalls = getAverageTime(AssistanceCalls, "Call Duration");
    const avCallCalls = getAverageTime(CallCalls, "Call Duration");
    const avAttCalls = getAverageTime(AttendanceCalls, "Call Duration");

    
    const cleanArrayToString=(careArr)=>{
        careArr = careArr.filter(function(item, pos) {
            return careArr.indexOf(item) == pos;
        });
        return careArr.join(",");
    }
    const cleanCareDelivered=(cArr)=>cArr.map(obj =>
        (Array.isArray(obj["Care Delivered"]))
            ? { ...obj, ["Care Delivered"]: cleanArrayToString(obj["Care Delivered"]) } 
            : obj,
    );
    
    function formatDuration(seconds) {
        // Check if seconds is negative
        if (seconds < 0) {
            // Convert negative seconds to positive
            seconds = Math.abs(seconds);
    
            // Calculate hours, minutes, and remaining seconds
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            let remainingSeconds = seconds % 60;
    
            // Format hours, minutes, and remaining seconds
            let formattedHours = String(hours).padStart(2, '0');
            let formattedMinutes = String(minutes).padStart(2, '0');
            let formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
            // Return the formatted duration with a negative sign
            return `-${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            // Calculate hours, minutes, and remaining seconds
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            let remainingSeconds = seconds % 60;
    
            // Format hours, minutes, and remaining seconds
            let formattedHours = String(hours).padStart(2, '0');
            let formattedMinutes = String(minutes).padStart(2, '0');
            let formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
            // Return the formatted duration
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    }
    

    const cleanDurationArr=(cArr)=>cArr.map(obj =>
        (obj["Call Duration"]>0)
            ? { ...obj, ["Call Duration"]: Moment("2015-01-01").startOf('day').seconds(obj["Call Duration"]).format('HH:mm:ss')} 
            : obj
    );
    
    const printCalls = cleanDurationArr(cleanCareDelivered(calls));
    
    const printObj = {
        title: displayTitle,
        //CallList: printCalls,
        CallSummary:[
            {
                title: "All Calls",
                ["Average Call Length"]: avAllCalls,
                Calls: calls.length
            },
            {
                title: "Emergency",
                ["Average Call Length"]: avEmgCalls,
                Calls: EmergencyCalls.length
            },
            {
                title: "Accessory",
                ["Average Call Length"]: avAccCalls,
                Calls: AccessoryCalls.length
            },
            {
                title: "Assistance",
                ["Average Call Length"]: avAssCalls,
                Calls: AssistanceCalls.length
            },
            {
                title: "Calls",
                ["Average Call Length"]: avCallCalls,
                Calls: CallCalls.length
            },
            {
                title: "Attendance",
                ["Average Call Length"]: avAttCalls,
                Calls: AttendanceCalls.length
            }
        ]
    }

    const excelPrint = {
        CallSummary:[
            {
                title: "All Calls",
                ["Average Call Length"]: avAllCalls,
                Calls: calls.length
            },
            {
                title: "Emergency",
                ["Average Call Length"]: avEmgCalls,
                Calls: EmergencyCalls.length
            },
            {
                title: "Accessory",
                ["Average Call Length"]: avAccCalls,
                Calls: AccessoryCalls.length
            },
            {
                title: "Assistance",
                ["Average Call Length"]: avAssCalls,
                Calls: AssistanceCalls.length
            },
            {
                title: "Calls",
                ["Average Call Length"]: avCallCalls,
                Calls: CallCalls.length
            },
            {
                title: "Attendance",
                ["Average Call Length"]: avAttCalls,
                Calls: AttendanceCalls.length
            }

        ],

        CallList: cleanDurationArr(cleanCareDelivered(printCalls)),
        EmergencyCalls: cleanDurationArr(cleanCareDelivered(EmergencyCalls)),
        CallCalls: cleanDurationArr(cleanCareDelivered(CallCalls)),
        AccessoryCalls: cleanDurationArr(cleanCareDelivered(AccessoryCalls)),
        AttendanceCalls: cleanDurationArr(cleanCareDelivered(AttendanceCalls)),
        AssistanceCalls: cleanDurationArr(cleanCareDelivered(AssistanceCalls)),
        CareCalls: cleanDurationArr(cleanCareDelivered(CareCalls)),
 
    }

    return ( 

            <DashboardSummaryContainer 
                displayTitle={displayTitle}
                All_Calls={calls}
                Emergency={EmergencyCalls}
                Call={CallCalls}
                Accessory={AccessoryCalls}
                Attendance={AttendanceCalls}
                Assistance={AssistanceCalls}
                Care_Call={CareCalls}
            />
   
    );

};



const getDateSelectionList = (props) => {   
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const options = [];
    
    let addDate = new Date();
    let dayBack1 = new Date();
    let dayBack2 = new Date();
    let dayBack3 = new Date();
    
    dayBack1.setDate(dayBack1.getDate() - 1);
    dayBack2.setDate(dayBack2.getDate() - 2);
    dayBack3.setDate(dayBack3.getDate() - 3);
    
    options.push({label:'Today', value: addDate});
    options.push({label:'Yesterday', value: dayBack1});
    options.push({label:days[dayBack2.getDay()], value: dayBack2});
    options.push({label:days[dayBack3.getDay()], value: dayBack3});
    
    return options;
}


function fieldFilterList (list, filterValueArr, filterField) {
    let RTNCALLS = [];

    function filterCallBack(f){
        return filterValueArr.indexOf(f["Call Type"]) > -1;
    }

    try {
        RTNCALLS = list.filter(filterCallBack);
    } catch (e) { }

    return RTNCALLS; 
}

function fieldFilter (list, filterValue, filterField) {
    let RTNCALLS = [];
    try {
        RTNCALLS = list.filter(row => row[filterField] ? row[filterField].includes(filterValue) : null).map(filteredName => (filteredName));
    } catch (e) { }

    return RTNCALLS; 
}

function getDateRefDisplay (ref) {
    let n;
    const options = getDateSelectionList();
    
    options.forEach((val) => {
        if (val.label === ref) {
            n = val.value;
        }
    });
    
    return n;
}

export default CompletedCalls;