import React from "react";

const PrimaryButton=(props)=>{
    
    return <button 
        className="bg-aqPrimary text-white borderRadius-2xl rounded p-1"
        onClick={()=>{
            if (props.onClick) props.onClick();
        }}
    >{props.children}</button>;

}

export default PrimaryButton;

