import React from 'react';
import './CircleGraph.css';

const CirclePercentageGraph=()=>{    

    return(
        <div className='circle_graph_container'>100</div>
    );

}

export default CirclePercentageGraph;