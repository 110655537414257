//window.user = "achapman@arquella.co.uk";
window.sites = [{label:"", value:""}];

window.zones = [];


window.selectedSite = "";

window.dashSelectedDate = "Today";
window.dashDateOptions = [];
window.dashZoneSelections = "";
window.dashZoneOptions= [];
window.dashCalls = "";
window.carers = [];


////////////REPORTS/////////////////////


//window.repSelectSite = "";
window.repCarers = [];
window.repCallTypes = [    
    {label:'Call', value:'Call'}, 
    {label:'Attendance', value:'Attendance'},
    {label:'Accessory', value:'Accessory'},
    {label:'Assistance', value:'Assistance'},
    {label:'Emergency', value:'Emergency'}, 
];
window.repZoneSelections = [
    {label:'Ground Floor', value:'Ground Floor'}, 
    {label:'First Floor', value:'First Floor'}, 
    {label:'second floor', value:'second floor'}, 
    {label:'Bathroom', value:'Bathroom'}, 
    {label:'Doorbell', value:'Doorbell'}, 
    {label:'Lower Ground Floor', value:'Lower Ground Floor'}, 
    {label:'Not Assigned', value:'Not Assigned'}
];
