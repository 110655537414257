import React, { useState} from "react";
import axios from 'axios';


const RawDataTable = (props) => {

    const [dbData, setData] = useState({
        data: "",
      });

    const getData = () => {
        axios.get("/resident/rpt/rawdata.php")
        .then(res => {
          setData({data: res.data.report});
        })
    }


    if (props.length > 0 ) {
        getData();
    }
    
    if (dbData.length > 0) {
        return (<p>We have some data</p>);
    }
    return (<p>No data</p>);


}

export default RawDataTable;