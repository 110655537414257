import React from 'react';
import DashboardItem from './DashboardItem/DashboardItem';

const DashSummaryContainer = (props) => {
    return (
                <div className='dashboard_grid_item arquella_scheme_secondary dashboard_grid_item_two_column'>
                    <div className='width-100 align-self-center text-align-center'>
                        <div className="dashboard_completed_calls_container">
                            <div className='dashboard_completed_calls_item align-left'></div>
                            <div className='dashboard_completed_calls_item align-left'></div>
                            <div className='dashboard_completed_calls_item align-center'>Total Calls</div>
                            <div className='dashboard_completed_calls_item align-center'>Average Calls</div>
                        </div>
                    </div>
                    {
                    Object.keys(props).map(k=>{
                        if(typeof(props[k])==="object"){
                            const title = k.replace("_"," ");
                            return <DashboardItem key={title} calls={props[k] ? props[k] : []} title={title} />;                            
                        }
                    })
                    }
                </div>
            );
}

export default DashSummaryContainer;