import firebaseconfig from '../firebase/firebaseindex';
import firebase from 'firebase'

export const authMethods = {
  // firebase helper methods go here... 
  signup: (email, password, setErrors,setToken ) => {
    firebase.auth().createUserWithEmailAndPassword(email,password) 
      //make res asynchonous so that we can make grab the token before saving it.
      .then( async res => {
        const token = await Object.entries(res.user)[5][1].b
        //set token to localStorage 
        await localStorage.setItem('token', token)
        setToken(token)
        //grab token from local storage and set to state. 
      })
      .catch(err => {
        setErrors(prev => ([...prev, err.message]))
      })
    },
  signin: (email, password, setErrors, setToken) => {
    //change from create users to...
    firebase.auth().signInWithEmailAndPassword(email, password) 
      //everything is almost exactly the same as the function above
      .then( async res => {

        firebase.apps[1].auth().signInWithEmailAndPassword(email, password).then((d)=>{console.log("authorised on 1")}).catch((e)=>{
          console.log("error on db signin 1");
          console.log(e);
        });
        firebase.apps[2].auth().signInWithEmailAndPassword(email, password).then((d)=>{console.log("authorised on 2")}).catch((e)=>{
          console.log("error on db signin 2");
          console.log(e);
        });

        const token = await Object.entries(res.user)[5][1].b
          //set token to localStorage 
          await localStorage.setItem('token', token)
          
          setToken(window.localStorage.token)
          localStorage.setItem("userEmail", email);
          
        })
        .catch(err => {
          setErrors(prev => ([...prev, err.message]))
        })
      },
      //no need for email and password
      signout: (setErrors, setToken) => {
      // signOut is a no argument function
    firebase.auth().signOut().then( res => {
      localStorage.clear();
      setToken(null);
    })
    .catch(err => {
      setErrors(prev => ([...prev, err.message]));
      localStorage.clear();    
      setToken(null);
      console.error(err.message);
    })
    },
  }
