import React, {useState} from 'react';
import { useEffect } from 'react';
import EditUserPage from './EditUserPage';
import User from './User';
import firebase from 'firebase';

const UserAdmin=()=>{

    const [popupUser, setPopupUser] = useState(null);
    const [staff, setStaff] = useState([]);
    
    
    useEffect(()=>{
        firebase.database().ref("adc_site/staff").on("value", snapshot => {
            let tmpStaff = [];
            console.log("snappy", snapshot);
            snapshot.forEach(snap => {
                const v = snap.val();
                tmpStaff.push(new User(snap.key, v.firstName, v.surname, v.passCode));
                console.log("tmpstaff", snap.val())
            });
            setStaff(tmpStaff);
        });

    },[]);

    console.log({staff});
    return(
        <div className=''>
            {(popupUser!==null)&&<EditUserPage 
                                            user={popupUser}
                                            callBackFunction={()=>setPopupUser()}
                                            />}
            UserAdmin
            
            {
            staff.map(s=>{return <div>{s.firstName}</div>})
            }

            <br />
            <button 
                onClick={()=>setPopupUser(new User("", "", ""))}
            >New User</button>
  

        </div>
    );
}



export default UserAdmin;

  