/**
 * Controller component for managing live calls.
 * @param {Object} props - The component props.
 * @param {string} props.homeSelection - The home selection value.
 * @returns {JSX.Element} The rendered component.
 */
import React, {useEffect, useState} from 'react';
import LiveCall from './LiveCall';
//import DashBoardCallContainer from '../DashBoardCallContainer/DashBoardCallContainer';
import './LiveCall.css';
import firebase from 'firebase'
import Moment from 'moment';
import { getDBSetting } from '../../../v2/localValues';
//import getLocalSetting from '../../../components/get_local_setting';


const NewLiveCallController = ({homeSelection}) => {

    const [data, setData] = useState([]);
    const getDateRef = (ref) => Moment(ref).format('DDMMYYYY');
    const dbPath = localStorage.getItem("dbPath");
    
    function getData(ref, dateVal) {
        
        const dbVal = getDBSetting();//localStorage.getItem("dbVal");
        console.log("dbVal", dbVal);
        firebase.apps[1].database(dbPath).ref(ref).on("value", snapshot => {
          
            let tmpCalls = [];
            snapshot.forEach(snap => {
                
                const r = snap.val();
                
                if (r.callType !== "") {
                
                    const fld = {
                        "Date" : dateVal,
                        "Zone" : r.zone,
                        "Resident" : "",
                        "Call Point" : r.room,
                        "Call Type" : (r.callType==="Sense") ? "Accessory" : r.callType,
                        "Start Time" : (r.startFullDate!==undefined) ? r.startFullDate : r.start,
                        "End Time" : r.end,
                        "Call Duration" : r.duration,
                        "Clear Type" : (r.end==="Live") ? "Live" : "Reporting", 
                        "Carer" : r.carer,
                        "Care Delivered" : r["Care Delivered"] ? r["Care Delivered"] : [],
                        "reason" : r["reason"] ? r["reason"] : "",
                        "comments" : r["comments"] ? r["comments"] : "",
                    };

                    if ((fld["Call Point"]!=="") && (fld["Call Point"]!=="undefined") && (fld["Call Point"]!==undefined)) {
                        tmpCalls.push(fld);
                    }

                }

            });

            setData(tmpCalls);

          });

    }

    useEffect(() => {
        getData(homeSelection, getDateRef(new Date()));
    }, []);

    console.log("SSSSSSSJJDJDDJ");

    return(
        <div className='dashboard_grid_item arquella_scheme_primary '>
        <div className='dashboard_title'>Live Calls</div>                    
    
{
    (data.length>0) && 
    data.map((c, i)=>{
        return(   
        <div
            style={{width:"100%"}}
            key={i}>

             <LiveCall
                    key={i}
                    index={i}
                    callType={c["Call Type"]}
                    room={c["Call Point"]}
                    zone={c.Zone}
                    carer={c.Carer}
                    careDelivered={c["Care Delivered"]}
                    className={"live_call"}
                    startDate={c["Date"]}
                    startTime={c["Start Time"]}
                    reason={c["reason"]}
                    comments={c["comments"]}
            />
        </div>             
    );
        })
    
}
        </div>

    );
}

export default NewLiveCallController;