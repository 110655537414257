export const getAverageTime = (arrOfObj, avField) => {
    let count = arrOfObj.length;
    let totalSecs = 0;

    arrOfObj.map(o=>{

        let fldVal = o[avField];
        if (!isNaN(fldVal)&&(fldVal!=null)) totalSecs += parseInt(fldVal);

    });

    const avr = totalSecs/count;
    const roundUp = Math.round(avr);
    const rtn = convertSecsToTime(roundUp);
    
    return rtn;
}

function formatDuration(seconds) {
    // Check if seconds is negative
    if (seconds < 0) {
        // Convert negative seconds to positive
        seconds = Math.abs(seconds);

        // Calculate hours, minutes, and remaining seconds
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainingSeconds = seconds % 60;

        // Format hours, minutes, and remaining seconds
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
        let formattedSeconds = String(remainingSeconds).padStart(2, '0');

        // Return the formatted duration with a negative sign
        return `-${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
        // Calculate hours, minutes, and remaining seconds
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainingSeconds = seconds % 60;

        // Format hours, minutes, and remaining seconds
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
        let formattedSeconds = String(remainingSeconds).padStart(2, '0');

        // Return the formatted duration
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
}

export const convertSecsToTime = (secs) => {
    let rtn = "";
    
    if ((!isNaN(secs))&&(secs!=null)) {
        try {
            if (secs < 0) return formatDuration(secs)
            let dt = new Date(null);
            dt.setSeconds(secs);
            rtn = dt.toISOString().substr(11, 8);
        } catch(err){
            return "ERR";
        }
    }
    else return "00:00:00";
    return rtn;
}