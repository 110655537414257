import React from "react";

const AuthContext = React.createContext({
    
    isLoggedIn: false,
    user: {},
    sites: [],
    permissions: [],
    sitePanels: [],
    
    onLogout: ()=>{}, 
    onLogin: (email, password)=>{},

});

export function getPermission(permissions, permissionName){
    console.log({permissions});
    try { return Boolean(permissions.filter(p=>p.permission===permissionName)[0].granted); }
    catch(e){ return false; }
};


export default AuthContext;