//import React, { Component } from "react";
//import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';
//import ReactPDF, { pdf, Page, Text, View, Document, StyleSheet, PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
//import ReactToPrint from 'react-to-print';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import './pdfExport.scss';
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';



const ToPDF = ({printObj, site, filterText}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div style={{display:"none"}}>
          <ComponentToPrint ref={componentRef}
            printObj={printObj}
            site={site}
            filterText={filterText}
            />
      </div>
      <div onClick={handlePrint}>PDF</div>
    </div>
  );
};


const CleanField = (fieldName, value) => {
  if (fieldName === "Date") return handleDate(value);
  return value;
}

const handleDate = (val) => {
  let rtn = "";
  const dt = new Date(val);
  try {
    rtn = ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth()+1)).slice(-2) + "/" + dt.getFullYear();
  } catch (e) {}
  return rtn;
}

const TableFromObjectArray = ({data}) => {

  const TableHeader = ({headers}) => <thead><tr>{headers.map(h=><th>{((h==="title" ? "" : h))}</th>)}</tr></thead>;
  const TableRow = ({data}) => <tr>{Object.keys(data).map(r=><td>{CleanField(r, data[r])}</td>)}</tr>;
  const TableBody = ({records}) => {return (<tbody>{records.map(r=><TableRow data={r} />)}</tbody>);};

  
  if (!Array.isArray(data)) return <p>No calls have been recorded for this time period.</p>;
  if (data.length===0) return <p>No calls have been recorded for this time period.</p>;
  
  return (
    <table>
      <TableHeader headers={Object.keys(data[0])} />
      <TableBody records={data} />
    </table>  
  );

}


export class ComponentToPrint extends React.PureComponent {
    render() {
      return (
        <div className="pdfExportPage">
          <img width="200" src={IMG_AIDA_LOGO} ></img>
          <br />
          <br />
          <h1>{this.props.site}</h1>
          <br />
          <br />
          <h2>Call Summary</h2>
          <br />
          <br />
          <h3>Todays Calls</h3>
          <br />
          <TableFromObjectArray data={this.props.printObj.CallSummary} />
          <br />
          <br />
          <h3>All Calls List</h3>
          <br />
          <TableFromObjectArray data={this.props.printObj.CallList} />
          <br />
          <br />
        
        </div>
      );
    }
  }


export default ToPDF;
