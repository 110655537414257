import React, {useState} from 'react';
import DataTable from '../../../components/common/DataTable/DataTable';
import DashboardIcon from './DashboardIcon';
import { Modal } from 'react-bootstrap';
import PrintOptions from '../../../components/PrintOptions';
import '../Dashboard.css';
import {convertSecsToTime} from '../../../components/common/time_helper';
import PrimaryButton from '../../../components/Buttons/primary_button';
import Moment from 'moment';

const DashboardItem = (props) => {
    
    const [showTable, setShowTable] = useState(false);  
    const calls = props.calls;

    const cancelHandler = () => {   setShowTable(false);    }
    const showPopup = () =>     {   setShowTable(true);     }

    function getAverage(totalVal, duration) {
        let rtnVal = "";
        let av = parseInt(duration/totalVal);
        if (av.toString !== "NaN") rtnVal = convertSecsToTime(av);
        return rtnVal;      
    }

    function addDuration(oldVal, addVal) {
        let rtnVal = parseInt(oldVal);
        try {
            rtnVal = rtnVal + parseInt(addVal);
        } catch(err){console.log("Error adding to duration:(" + addVal + ") - " + err);}
        return rtnVal;
    }
    
    const cleanArrayToString=(careArr)=>{ return careArr.toString(); }
    const cleanCareDelivered=(cArr)=>cArr.map(obj =>
                        (Array.isArray(obj["Care Delivered"])) ? { ...obj, ["Care Delivered"]: cleanArrayToString(obj["Care Delivered"]) } : obj
    );
 
    const cleanDurationArr=(cArr)=>cArr.map(obj =>
        (obj["Call Duration"]>0)
             ? { ...obj, ["Call Duration"]: Moment("2015-01-01").startOf('day').seconds(obj["Call Duration"]).format('HH:mm:ss')} 
             : obj
     );
 

    const GetPopup = ({calls, showTable, title, cancelHandler, careCalls}) => {

        if (Array.isArray(calls)) {
            
            const excelPrint = {
                CallList: cleanDurationArr(cleanCareDelivered(calls)),
            }
            return(

                <Modal 
                    size="lg"
                    show={showTable} 
                    onHide={cancelHandler}
                    >

                    <div className="popupStyle">
                    
                        <br />
                        <h1>{title}</h1>
                        <br />
                        
                        <div className="center-self-align">
                            <PrintOptions
                                wordOBJ={excelPrint}
                                excelOBJ={excelPrint}
                                pdfOBJ={excelPrint}
                            />
                        </div>
                        <br />
                
                        <DataTable hideFilter={true} data={calls} careData={careCalls} />    
                
                        <br />
                        <br />
                        <div>
                            <PrimaryButton
                                onClick={cancelHandler}
                            >Cancel</PrimaryButton>
                        </div>
                        
                        <br />
                        <br />
                        <br />
                    
                    </div>

                </Modal>

            ); 
        }
        else {
            return (<div></div>);
        }

    }
   
        const cll = calls;

        let intTotal = 0;
        let intAvr = 0;
        let allAvr = 0;
        
        if (cll.length > 0) {
            cll.map(data => {
                let isAttendance = false;

                if (!isAttendance) {
                    if (data != null) {
                        if (data["Call Duration"] !== "Live") {
                            intTotal++;
                            intAvr = addDuration(intAvr, data["Call Duration"]);
                        }
                    }
                }
        
            });
            allAvr = getAverage(intTotal, intAvr);
        }
        

        const buttonStyle = {
            borderRadius: "10px",
            width: "100%",
            //height: "85px",
            
            border: "none",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "white",
            backgroundColor: colours[props.title],
            marginTop: "0.3em",
            marginBottom: "0.3em",
            paddingTop: "0.6em",
            paddingBottom: "0.6em",
            
        }
        



        return (
            <div className='width-100 align-self-center text-align-center'>
                <button 
                    style={buttonStyle} 
                    onClick={()=> {
                        if (Array.isArray(props.calls)) {
                            showPopup();
                        }
                }}> 

                    <div className="dashboard_completed_calls_container">
                        <div className='dashboard_completed_calls_item align-center'><DashboardIcon callType={props.title.toString()} /></div>
                        <div className='dashboard_completed_calls_item align-left'>{props.title}</div>
                        <div className='dashboard_completed_calls_item align-center'>{intTotal}</div>
                        <div className='dashboard_completed_calls_item align-center'>{(allAvr===0)?"00:00:00":allAvr}</div>
                    </div>
            </button>

            <GetPopup 
                calls={props.calls} 
                showTable={showTable} 
                title={props.title}
                cancelHandler={cancelHandler}
                careCalls={props.careCalls}
            ></GetPopup>
            
            </div>       
        );
    
}

const colours = {
    "All Calls": "#4cc1bd",
    "Emergency": "#EC1848",
    "Call": "#F4804D",
    "Accessory": "#914397",
    "Attendance": "#93C967",
    "Assistance": "#F8DA3C",
    "Care Call": "#e11583",
    "Visit": "#01573f"
};


const dashItemRight = {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    justifyContent: "space-around",
    height: "100%"
};

const dashboard_top = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
}  

const hr = {
    backgroundColor: "white",
    width: "100%",
    border: "0px",
    padding: "0px",
    height: "2px",
    color: "white",
    marginBottom:"0px",
    marginTop:"0px"
}

const dashboardBottomStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
}

export default DashboardItem;

