import React from 'react';
import axios from 'axios';

export default class NodeTest extends React.Component {

    render() {
        return(
            <div>
                <CallsToProcess />

            </div>);
    }

} 


export class CallsToProcess extends React.Component {

    state = {
        calls: "N/A"
    }

    componentDidMount() {
        let sData = {
            "userkey": localStorage.getItem("userEmail"), 
            "info" : "ProcessCallsCount"
          };
          axios.post("system", sData)
          .then(response => {
            this.setState({calls: response.data})
          });
    }

    render() {
        return(<div>Calls waiting to process: {this.state.calls};</div>);
    }

} 
