import React, {useState} from 'react';
import DropSelect from '../common/DropSelect/DropSelect';


const Filter = (props) => {

    const [filterOptions, setFilterOptions] = useState({
        homeOptions: props.options,
        homeSelection: props.selection,
    });
    
    const changeSite = (site) => {
        setFilterOptions({
            homeOptions: filterOptions.homeOptions,
            homeSelection: site,
        });
        props.callback(site);
    }

    return <DropSelect 
                options={filterOptions.homeOptions} 
                selectedVal={filterOptions.homeSelection} 
                changeHandler={changeSite} />;

}

export default Filter;