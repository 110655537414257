import firebase from 'firebase'

const firebaseConfig2 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-afe91.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};

const firebaseConfig1 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e.firebaseio.com",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};

const dbConfig = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-b7352.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};

const dbConfig4 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-d2306.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};


const dbConfig5 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-d2306.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};



const dbConfig6 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-5c7be.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};



const dbConfig7 = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  databaseURL: "https://arquella-cc76e-39485.europe-west1.firebasedatabase.app",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};


const dbConfigg = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};


const dbConf = {
  apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
  authDomain: "arquella-cc76e.firebaseapp.com",
  projectId: "arquella-cc76e",
  storageBucket: "arquella-cc76e.appspot.com",
  messagingSenderId: "673271376643",
  appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
  measurementId: "G-10LF2R4MV2"
};
  // Initialize Firebase
  
  //firebase.initializeApp(firebaseConfig1);


  firebase.initializeApp(dbConfig);
  firebase.analytics();
  
  firebase.initializeApp(firebaseConfig1, "db1");
  firebase.initializeApp(firebaseConfig2, "db2");
  firebase.initializeApp(firebaseConfig2, "db3");
  firebase.initializeApp(dbConfig4, "db4");
  firebase.initializeApp(dbConfig5, "db5");
  firebase.initializeApp(dbConfig6, "db6");
  firebase.initializeApp(dbConfig7, "db7");

  
  console.log("initialising firebase");

  export function checkPanelDB(){
    return new Promise((resolve, reject)=>{
      firebase.database().ref("panels").once("value", snapshot => {
        const dat = snapshot.val();
        console.log("vallllll", dat);
        try {
          const list = Object.values(dat).map(d=>d.siteRef);
          localStorage.setItem("dblist", JSON.stringify(list))
        }
        catch(e){ console.log(e); }
        resolve(dat);
      });    
    })
  }


  //const firebaseApp1 = firebase.initializeApp(firebaseConfig1, "db1");
  //const firebaseApp2 = firebase.initializeApp(firebaseConfig2, "db2");
  
  // Get references to the database for each app
  //const db1 = firebaseApp1.database();
  //const db2 = firebaseApp2.database();


//export {db1, db2}