import firebase from 'firebase';
//import AuthContext from '../../AuthContextProvider';

export const firebaseOnValue=(reference, onData, onError)=>{
    if ((reference==="")||(reference==="")) {
        onError("Invalid reference value : " + reference.toString());
        return;
    }

    firebase.database().ref(reference).on("value", snapshot => {  
        const data = [];
        snapshot.forEach((snap)=>{data.push(snap.val())});
        onData(data); 
    });    
}

export const firebasePush=(reference, value, onData, onError)=>{
    if ((reference==="")||(reference==="")) {
        onError("Invalid reference value : " + reference.toString());
        return;
    }
    console.log("FBPush");
    console.log({reference});
    console.log(value);

 //   return;
    firebase.database().ref(reference).push().set(value)
    .then(d=>{
        console.log({d});
    })
    .catch(e=>{
        console.log({e});
    })
    ;
}

