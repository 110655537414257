import React from 'react';
import Timer from 'react-compound-timer';
import moment from 'moment/moment';

export default class UpTimer extends React.Component {

    isValidDate(value) {
        var dateWrapper = new Date(value);
        return !isNaN(dateWrapper.getDate());
    }

    render() {

        let startTime = this.props.startTime;
        let timerSecs = 0; 

        if (this.isValidDate(startTime)) {
            console.log({startTime});
            const startDate = moment(startTime, "YYYY/MM/DD HH:mm:ss");
            const timeEnd = moment();
            console.log({startDate, timeEnd});
            const diff = timeEnd.diff(startDate);
            console.log({diff});
            let t = moment.duration(diff);
            console.log({t});

            timerSecs = t.asMilliseconds();

        }

        return(
            <div>
                <Timer
                    initialTime={timerSecs}
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                    //direction="backward"
        >
                    {() => (
                        <React.Fragment>
                            <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                        </React.Fragment>
                    )}
                </Timer>
            </div>
        );
    }
}