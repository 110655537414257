import { 
    Document, Packer, Paragraph, TextRun,  ImageRun,
    Table, TableRow, TableCell, WidthType, Footer, ShadingType, AlignmentType, HeadingLevel, BorderStyle,    
} from "docx";
import IMG_AIDA_LOGO from '../../images/icons/aida_logo.png';

async function createWordDoc(printObj, site, filterText) {

    let sectionChildren = [];
    
    sectionChildren.push(   HEADER1(site)  )
    sectionChildren.push(   HEADER2("Call Summary")  )
    sectionChildren.push(   HEADER3(printObj["title"])  )
    
    const arrCallSummary = getArray(printObj, "CallSummary");
    const summary = getCallSummary(arrCallSummary);
    if (summary.length > 0) sectionChildren = sectionChildren.concat(summary);

    const arrCalls = getArray(printObj, "CallList");
    const callTable = getCallsTable(arrCalls);
    if (callTable.length > 0) sectionChildren = sectionChildren.concat(callTable);

    const logo = await fetch(IMG_AIDA_LOGO);
    
    const doc = new Document({
        creator: "AIDA",
        title: "Arquella daily report",
        description: "Arqella report",
        styles: {
            paragraphStyles: [
                {
                    id: "HEADING_1",
                    name: "Heading 1",
                    basedOn: "Normal",
                    next: "Normal",
                    font:"arial",            
                    quickFormat: true,
                    run: {
                        size: 20,
                        bold: true,
                        italics: false,
                        color: "#4cc1bd",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                {
                    id: "HEADING_2",
                    name: "Heading 2",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    font:"arial",         
                    run: {
                        size: 18,
                        bold: false,
                        color: "#4cc1bd",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
                {
                    id: "HEADING_3",
                    name: "Heading 3",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    font:"arial",         
                    run: {
                        size: 16,
                        bold: false,
                        color: "#4cc1bd",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
                {
                    id: "TD",
                    name: "Table Cell",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "999999",
                    },
                    
                },
                {
                    id: "wellSpaced",
                    name: "Well Spaced",
                    basedOn: "Normal",
                    quickFormat: true,
                    paragraph: {
                        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
                    },
                },
                {
                    id: "ListParagraph",
                    name: "List Paragraph",
                    basedOn: "Normal",
                    quickFormat: true,
                },
            ],
        },
        numbering: {
            config: [
                {
                    reference: "my-crazy-numbering",
                    levels: [
                        {
                            level: 0,
                            format: "lowerLetter",
                            text: "%1)",
                            alignment: AlignmentType.LEFT,
                        },
                    ],
                },
            ],
        },
        background: {
        //  color: "C45911"
        },
        sections: [{
            properties: {},
            /*
            headers: {
                default: new Header({
                    
                    children: [
                                new Paragraph(site)
                    ],
                    
                }),
            },
            */
            footers: {
                default: new Footer({
                    children: [

                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: await logo.blob(),
                                    transformation: {
                                        width: 50,
                                        height: 25,
                                    },
                                }),
                            ]
                            
                        }),
                        new Paragraph("Capturing moments of care.")

                    ],
                }),
            },
            children: sectionChildren,
         }]
    });

    const blob = await Packer.toBlob(doc);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'my.docx';
    link.click();

}

const tableCellPartBorderStyle = {
    style: BorderStyle.SINGLE,
    size: 3,
    color: "#4cc1bd",
};

const tableBorderStyle = {
    top: tableCellPartBorderStyle,
    bottom: tableCellPartBorderStyle,
    left: tableCellPartBorderStyle,
    right: tableCellPartBorderStyle,
};

//const newLine = new TextRun({text: "", break: 2,});
const P = (content) => new Paragraph({children: [new TextRun({text:content, font: "arial"}),]});
const TABLE = (rows) => new Table({ columnWidths: [3505, 5505], rows: rows });
const TR = (cells) => {return new TableRow({children: cells,});}
const TD = (content) => {
    return new TableCell({
        width: { size: 3505,type: WidthType.DXA},
        borders: tableBorderStyle,
        children: [new Paragraph({
            text: content ? content : "",
            heading: HeadingLevel.TD,
            font: {
                name: "Arial",
            },})],
    });
}

const TH = (content) => {
    return new TableCell({
        width: { size: 3505,type: WidthType.DXA },
        shading: {
            fill: "#4cc1bd",
            type: ShadingType.PERCENT_95,
            color: "auto",
        },
        children: [new Paragraph(content)],
    });
}




const HEADER1 = (content) => {
    return new Paragraph({
        text: content,
        heading: HeadingLevel.HEADING_1,
        font: "arial"
    });
}

const HEADER2 = (content) => {
    return new Paragraph({
        text: content,
        heading: HeadingLevel.HEADING_2,
    });
}

const HEADER3 = (content) => {
    return new Paragraph({
        text: content,
        heading: HeadingLevel.HEADING_3,
    });
}

function getArray(obj, index){
    let rtn;
    if (Object.keys(obj).indexOf(index) > -1) {
        rtn = obj[index];
    }
    if (!Array.isArray(rtn)) rtn = [];
    return rtn;
}


function getCallSummary(arrCalls) {
    let rtn = [];

    let rows = [];
    
    rows.push(
        TR([
            TH(""),
            TH("Number of calls"),
            TH("Average Call Length")
        ])
    );
    
    arrCalls.map(c=>{

        const totalCalls = c["Calls"].toString();
        const avr = c["Average Call Length"] ? c["Average Call Length"] : 0;
 
        rows.push(
            TR([
                TD(c.title),
                TD(totalCalls),
                TD(avr),
            ])
        );

    });
    const sumTable = TABLE(rows);
    rtn.push(sumTable);
   
   return rtn;
}

function getCallsTable(arrCalls) {
    let rtn = [];

    rtn.push(HEADER3("All Calls List"));
    let rows = [];
    
    
    if ((Array.isArray(arrCalls)) && (arrCalls.length>0)) {
        //get the headers
        let headers = [];
        Object.keys(arrCalls[0]).map(k=>{
            headers.push(TH(k));
        });
        
        rows.push(TR(headers));

        arrCalls.map(c=>{
        
            let columns = [];
        
            Object.keys(c).map(k=>{
                let v = c[k]; 
                
                if (k=="Date") {
                    const dt = new Date(v);
                    v = ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth()+1)).slice(-2) + "/" + dt.getFullYear();
                }
                columns.push(TD(v));
            });
        
            rows.push( TR(columns) );

        });
        

        let callTable = TABLE(rows);
        rtn.push(callTable);
    }
    else {
        rtn.push(P("No calls recorded"));
    }
    return rtn;
}


const arquellaImage = async ()=>  {

    const logo = await fetch(IMG_AIDA_LOGO);

    return new ImageRun({
    
        data: await logo.blob(),
        transformation: {
            width: 200,
            height: 200,
        },
        floating: {
            horizontalPosition: {
                offset: 1014400,
            },
            verticalPosition: {
                offset: 1014400,
            },
        }
    
    });
}


export default createWordDoc;
