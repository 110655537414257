// add useContext
import React, {useContext} from 'react';
import {firebaseAuth} from '../firebase/provider/AuthProvider';
import IMG_LOGO from '../images/logo.jpg';
import PrimaryButton from './Buttons/primary_button';

const Signin = () => {

  const {handleSignin, inputs, setInputs, errors} = useContext(firebaseAuth)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    handleSignin()
    localStorage.setItem("userEmail", inputs.email);
  }
  const handleChange = e => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* replace the div tags with a form tag */}

      <div className="loginObjects">  
        <img alt="Arquella Logo" id="ArquellaLogo" src={IMG_LOGO} className="signinLogo" />
        {/* make inputs  */}
        <input 
          onChange={handleChange} 
          id="email" 
          className="rounded-t-md p-1"
          type='email' 
          name="email" 
          placeholder='email' 
          value={inputs.email} 
        />
        
        <input 
          onChange={handleChange} 
          className="rounded-b-md p-1"
          type="password" 
          id="password" 
          name="password" 
          placeholder='password' 
          value={inputs.password} 
        />
        <br></br>
        <PrimaryButton>Login</PrimaryButton>
      </div>
      {errors.length > 0 ? errors.map(error => <p style={{color: 'red'}}>{error}</p> ) : null}
    </form>
  );
};

export default Signin;