import React, {useState, useRef, useEffect, componentRef} from 'react';
import firebase from 'firebase';
import moment from 'moment'
import * as d3 from "d3";

const DayView = ({monitoringRef, title}) => {

    const gRef = useRef(null);
    const [calls, setCalls] = useState([]);
    const [selectedCallTypes, setSelectedCallTypes] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);

    useEffect(()=>{ 
        console.log("monitoringRef", monitoringRef);
        firebase.database().ref(monitoringRef).on("value", snapshot => {
     
            const tmpCallsArr = new Array();

            snapshot.forEach(snap => {
     
                const r = snap.val();
    
                tmpCallsArr.push(
                    {
                        "Date" : "",//getDateRefDisplay(rDate),// Moment(dRef, "DDMMYYYY"),
                        "Zone" : r.zone,
                        "Resident" : "",
                        "Call Point" : r.room,
                        "Call Type" : r.callType,
                        "Start Time" : r.start,
                        "End Time" : r.end,
                        "Call Duration" : r.duration,
                        "Carer ID" : r.carer,
                        "Care Delivered" : r["Care Delivered"] ? r["Care Delivered"].toString() : "" 
                    }
                );

            });
            console.log("dd2", typeof(tmpCallsArr));
            setCalls(tmpCallsArr);
        });
        

    }, [monitoringRef]);


    useEffect(()=>{

        let tmpCalls = calls.filter(c=>((c["Call Duration"]!=="Live"))).map(c=>{
            return {
                timeFrom: '2020-11-11T' + c["Start Time"] + '.000Z',
                timeTo: '2020-11-11T' + c["End Time"] + '.000Z',
                title: c["Call Type"],
                carer: c["Carer ID"],
                background: getCallColour(c["Call Type"])
              }
        });            
//        tmpCalls = tmpCalls.filter(c=>selectedCallTypes.includes(c.title));
        setCalendarEvents(tmpCalls);
    },[calls, selectedCallTypes]);

  function getCallColour(call){
      if (call==="Attendance") return "green";
      if (call==="Accessory") return "yellow";
      if (call==="Call") return "orange";
      
      return "pink";
  }


//  calendarEvents = calendarEvents.filter(c=>c.title==="Attendance")

  const dates = [
    ...calendarEvents.map(d => new Date('2020-11-11T00:00:00.000Z')),
    ...calendarEvents.map(d => new Date('2020-11-11T23:59:59.000Z'))
  ];
  
  function updateSelections(val) {
    let tmpA = selectedCallTypes.map(c=>c);

    if (tmpA.includes(val)) {
        console.log("remove it");
        tmpA = tmpA.filter(v=>v!==val);
    } else {
        console.log("add it");
        tmpA.push(val);
    }
    setSelectedCallTypes(tmpA);
  }

// All further code additions will go just below this line

    useEffect(()=>{
        gRef.current.innerHTML = "";

        if (calendarEvents.length>0) {

        const calenderGroups = groupByKey(calendarEvents, "carer", false);  
        console.log("calenderGroups", calenderGroups);

        const margin = { top: 30, right: 30, bottom: 30, left: 50 }; // Gives space for axes and other margins
        const height = ((margin.top + margin.bottom) + (Object.keys(calenderGroups).length*50));
        const width = 1000;
        const barWidth = 1000;
        const barHeight = 50;
        const barStyle = {
          background: '#616161',
          textColor: 'black',
          width: barWidth,
          height: barHeight,
          startPadding: 2,
          endPadding: 3,
          radius: 3
        };

        const xScale = d3
            .scaleTime()
            .domain([d3.min(dates), d3.max(dates)])
            .range([margin.left, width - (margin.right)]);

        const yScale = d3
            .scaleBand()    
            .domain(["1","3"])
            .range([0, 50]);

        const xAxis = d3
            .axisBottom()
            .ticks(24)
            .scale(xScale);

        var svg = d3.select(gRef.current)
          .append("svg")
          .attr('width', width)
          .attr('height', height);

          svg
          .append('g')
          .attr('transform', `translate(${margin.left},${(height-margin.bottom)})`)
          .attr('opacity', 0.5)
          .call(xAxis);


    const barGroups = svg
    .selectAll('g.barGroup')
    .data(Object.keys(calenderGroups), function(d,i){
      return d;
    })
    .enter()
    .append('g');
    
    barGroups
          .append('text')
          .attr('font-family', 'Roboto')
          .attr('font-size', 14)
          .attr('font-weight', 500)
          .attr('text-anchor', 'start')
          .attr('fill', barStyle.textColor)
          .attr('x', xScale())
          .attr('y', (d,i) => margin.top + (i*50) + 22)
          .text(d=>d)
          .selectAll("text")
          .data(function(d,i){
            console.log("dddddsssa", d);
            
            const dta = calenderGroups[d];

            dta.map((rect, dataCount)=>{
              
              const recWidth = 10;
              const recHeight = 30;

              const scaleStart = xScale(new Date(rect.timeFrom));
              const scaleEnd = xScale(new Date(rect.timeTo));

              svg
              .append('g')
              .append('rect')
              .attr('fill', getCallColour(rect.title))
              .attr('width', (scaleEnd - scaleStart))
              .attr('height', recHeight)
              .attr('x', margin.left + scaleStart)//((margin.left + margin.right) + (1 * dataCount)))
              .attr('y', margin.top + (i*50));
            })
                        
            return d;
          });
          
        
          

//.attr('class', 'barGroup')

/*
            const barGroups = svg
            .selectAll('g.barGroup')
            .data(d3.keys(calenderGroups))
            .enter().append('g')
            //.enter().append('path')
              .attr('class', 'barGroup');

          barGroups
          .append('text')
          .attr('font-family', 'Roboto')
          .attr('font-size', 14)
          .attr('font-weight', 500)
          .attr('text-anchor', 'start')
          .attr('fill', barStyle.textColor)
          .attr('x', 0)
          .attr('y', (d,i) => margin.top + (i*50))
          .text(d=>d);    

          

          const barGroups = svg
          .selectAll('g.barGroup')
          .data(d3.keys(calenderGroups))
          .enter().append('g')
          //.enter().append('path')
            .attr('class', 'barGroup');

          const rowBars = svg
          .data(calenderGroups, function (d, i){
            return calenderGroups[d].data;
          })
          .enter()
          .append('rect')
          .attr('fill', d => d.background || barStyle.background)
          .attr('x', margin.left)
          .attr('y', d => yScale(new Date(d.timeFrom)) + barStyle.startPadding)
          .attr('height', d => {
                    console.log("eachrec", d);
                    const startPoint = yScale(new Date(d.timeFrom));
                    const endPoint = yScale(new Date(d.timeTo));
                    return (
                        (endPoint - startPoint) 
                        //endPoint - startPoint - barStyle.endPadding - barStyle.startPadding
                    );
          })
          .attr('width', barStyle.width)
          .attr('rx', barStyle.radius);

/*
              const currentTimeDate = new Date(new Date(new Date().setDate(11)).setMonth(10)).setFullYear(2020);
*/
                }

            },[calendarEvents]);

                return (
        <div className="flex flex-row">
          {/*
            <div className='flex flex-col'>
                <div>Filter</div>
                {calls
                    .map(c=>c["Call Type"])
                    .filter((v, i, a) => a.indexOf(v) === i)
                    .map(r=>{
                        return <div><input 
                                            type='checkbox' 
                                            checked={selectedCallTypes.includes(r)}
                                            onClick={()=>updateSelections(r)} 
                                    />{r}</div>
                    })}

            </div>
                  */}
            <div ref={gRef} className="gBarChart"></div>
        </div>
    );

}

const groupByKey = (list, key, {omitKey=false}) => list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})

export default DayView;