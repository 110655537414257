import React, {useState, useEffect} from 'react';
import firebase from 'firebase';
import './LiveCalls.css';
import { sendCallCode } from './dbController';

const MonitorLivePhoneDB=({siteName})=>{

    const [data, setData] = useState([]);
    
    function getConfig(){
        console.log("Phone site", siteName);
        firebase.database().ref(siteName + "PhoneLiveCalls").on("value", snapshot => {            
            let eData = [];
            snapshot.forEach(snap => {                
                eData.push({"Name":[snap.key], "Value":snap.val()});
            });
            setData(eData);
        });
    }
    
    useEffect(()=>{ getConfig(); }, [siteName]);

    function toDateFormat(strDate){
        if (strDate==undefined) return "";
        let d = new Date().setTime(Date.parse(strDate));        
        return d;
    }

    function removeRecord(recordpath){
        recordpath = siteName + "PhoneLiveCalls/" + recordpath;
        console.log("To remove.....  " + recordpath);
        firebase.database().ref(recordpath).remove();
    }

    function sendClearCode(roomCode){
        sendCallCode("arquellacare", "001", "3");
    }


    if (data.length==0) return(<>There are currently no live calls.</>);

    return(
        <div className='flex-column grey_frame LiveCallsContainer'>
            <h1>'{siteName}' live calls DB</h1>
            <div className='flex-column'>
                <table>
                    <thead>
                        <tr>
                            <th>Room</th>
                            <th>Room code</th>
                            <th>Call type</th>
                            <th>Start</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    data.map((d,i)=>{
                        let startDate = toDateFormat(d.Value.start);
                        let classVal = "flex-row highlight_hover ";
                        if (startDate == "") classVal += "alert_monitoring";
                        return (
                            <tr key={i+"SiteLiveCallList"}>
                                <td>{d.Value.room}</td>
                                <td>{d.Name}</td>
                                <td>{d.Value.callType}</td>
                                <td>{d.Value.start}</td>
                                <td>
                                    <button onClick={()=>sendClearCode()}>Send Clear Code</button>
                                    <br />
                                    <button onClick={()=>removeRecord()}>Remove From Live DB</button>
                                </td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}


const showData=()=>{
    
    return({/*
    <div>
        {
        Object.keys(d.Value).map(k=>{
            return(
                <div className='flex-row'>
                    {
                    (k=="Care Delivered") 
                    ? <div>Care Delivered</div>
                    :   <>
                            <div><b>{k}:&nbsp;&nbsp;&nbsp;</b></div>
                            <div>{d.Value[k]}</div>
                        </>
                    }
                </div>
            );
        })
        }
        <button
            onClick={()=>removeRecord(d.Name)}
        >Remove Record</button>
    <div>
    */}
    );
}
export default MonitorLivePhoneDB;