import React, {useState} from "react";
import '../integrations.css';
import {TextInputField} from '../../components/Forms/Forms';

const NourishMenu=({SEC})=>{

    const siteList = (SEC.sites) ? SEC.sites : [];

    return(
        <div className="integrations_container">
            <h2>Nourish</h2>
            <div>Nourish integration relies on generating a site code from Arquella and then entering this into the Nourish system.</div>
            <br />
            {/*<div className="integration_site_list">{siteList.map((p,i)=><SitesConfig key={"scfg"+i} site={p} />)}</div>
            */}
            <div className="integration_site_list"><SitesConfig site={siteList[0]} /></div>
            <br />
        </div>
    );
}


const SitesConfig=({site})=>{
    
    const [clientToken, setClientToken] = useState("clientToken"); 

    console.log(clientToken);
    return(
        <div>
            <div>Nourish configuration for {site.name}</div>
            <div className="flex-row">
                <div>
                <TextInputField 
                    title={"Client token"}
                    //name={"clientToken"}
                    value={clientToken}
                    //type={""}
                    onValueChanged={(val)=>setClientToken(val)}
                />
                </div>
                &nbsp;
                <GenerateTokenUI currentToken={clientToken} onValueChanged={setClientToken} />
            </div>
        </div>
    );
}

const GenerateTokenUI=({currentToken, onValueChanged})=>{

    const [generatedToken, setGeneratedToken] = useState("");
    const [tokenCount, setTokenCount] = useState(0);
    const tokenList = ["2a5db6ba-50fa-4d67-8e49-259cc56ab46b", "ec57f388-b00e-4848-b712-18bbf3ca32e8", "ce147b5d-ca7e-4e4a-9887-857d00921349", "6f9ee3d6-75e8-46cc-ac1c-38aba22e3e11", "89808524-d6e5-4578-b397-675963928916", "3b68fcaa-4802-458b-a71b-edc4092be433", "e585ac32-fc54-4e8f-b49e-050ca2961f79", "7b1cf40c-d084-43e8-8a8b-a43c58f625a7"];

    function generateToken(){
        let tkn = tokenCount + 1;
        if (tkn >= tokenList.length) tkn = 0;
        //setGeneratedToken(tokenList[tkn]);
        setTokenCount(tkn);
        onValueChanged(tokenList[tkn]);
        
    }

    return <button onClick={()=>generateToken()}>{currentToken=="" ? "Generate client token" : "New client token"}</button>;

}

export default NourishMenu;