import firebase from 'firebase';

export const getDBRef=()=>{

    return new Promise((resolve, reject)=>{
/*
        firebase.database().ref(siteName + "PhoneLiveCalls").on("value", snapshot => {            
            let eData = [];
            snapshot.forEach(snap => {                
                eData.push({"Name":[snap.key], "Value":snap.val()});
            });
            resolve(eData);
        });
*/
    });

}

export const sendCallCode=(site, room, code)=>{
    firebase.database().ref(site + "/call").set(room + "." + code);
}
