import React, {useState} from "react";
import './Forms.css';
import { useEffect } from "react";

export const TextInputField=({title, name, value, type, onValueChanged})=>{
    
    const [errorText, setErrorText] = useState("");
    const [fieldValue, setFieldValue] = useState(value);
    
    useEffect(()=>{ setFieldValue(value); },[value]);
    
    function valueChange(event){
        onValueChanged(event.target.name, event.target.value);
        setFieldValue(event.target.value);
    }
    
    return (
        <>
            <div className="formInputTitle">{title}</div>
            <input name={name} value={fieldValue} type={type} onChange={valueChange} />
            <span className="formInputAlert">{errorText}</span>
        </>
    )
}

export const TextAreaField=({title, name, value, type, onValueChanged})=>{
    
    const [errorText, setErrorText] = useState("");
    const [fieldValue, setFieldValue] = useState(value);

    function valueChange(event){
        onValueChanged(event.target.name, event.target.value);
        setFieldValue(event.target.value);
    }
    

    return (
        <>
            <div className="formInputTitle">{title}</div>
            <textarea name={name} onChange={valueChange} value={fieldValue} rows="10" cols="80"></textarea>
            <span className="formInputAlert">{errorText}</span>
        </>
    )
}