
function User(id, first_name, surname, pCode){
    return {
        id: id,
        firstName: first_name,
        surname: surname,
        pCode: pCode
    };
}

export default User;