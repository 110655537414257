import getLocalSetting from '../../components/get_local_setting';

function cleanData(data, configData) {
    
    //const usersList = JSON.parse(localStorage.getItem("carers"));
    const usersList = JSON.parse(getLocalSetting("carers"));

    if (!Array.isArray(data)) return [];
    if (data.length === 0) return [];
    
    let rtnData = data;
    //rtnData = rtnData.filter(f=>(f["Call Point"]==="BEDROOM 6"));
    
    console.log("rtnData", rtnData);

    rtnData.map(d=>{
        
        d["Carer ID"] = getEmailUserDisplay(d["Carer ID"], usersList ? usersList : []);
        
        let tmpCare = getCareObject(d["Care Delivered"]);
        
        if (d["Care Delivered"]!==null) {
            d["Care Delivered"] = Object.keys(tmpCare).map(c=>{
//                return (tmpCare[c].CallType!=="Comments") ? tmpCare[c].CallType : {"comment":tmpCare[c].CallValue}
                return (tmpCare[c].CallType!=="Comments") ? tmpCare[c].CallType : tmpCare[c].CallValue
  
            });
        }
        else {
            d["Care Delivered"] = "";
        }

    });
            
    return rtnData;
};


const getCareObject=(care)=>{
    if (typeof(care)==="object") return care;
    if (typeof(care)==="string") return JSON.parse(care);
    return {};
}


const getEmailUserDisplay=(email, userList)=>{
    if (!Array.isArray(userList)) return "";
    if ((email===null)||(email==="")) return "";
    if (email.indexOf("@")===-1) return email;
    if (Array.isArray(userList)) return email;
    if (userList.length===0) return email;
    let displayName = "";
    const jList = userList;
    displayName = jList.filter(j=>j.value)[0].label;
    return displayName;
}

export default cleanData;
