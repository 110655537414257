import React, {useState, useRef, useEffect, componentRef} from 'react';
import DayView from './DayView';

const CallCalendarPage = (props) => {

    const dateRef = "05052022";

    return (

        <div  
            //        id="AIDA_NAV_MAIN"
            style={{width:"90%"}}>
               <DayView monitoringRef={"tranbyCallRecords-First Floor/" + dateRef} />
                        
        </div>
    );
}

export default CallCalendarPage;