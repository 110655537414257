import React, { useEffect } from 'react';
import axios from 'axios';
import IIWARI_CONNECTOR from './iiwari';

const LocationPage=()=>{

    //const site_guid = "018290c0-4a7b-9f03-18bb-64cba6e030a5";     //arquella office
    //const site_guid = "018586db-51a1-61b1-9b05-2ba58ffac7a3";   //weir
    //const site_guid = '016fd235-8e10-1486-23f6-5e6cc6f4827b'; //iiwari
    const site_guid = '018290c0-4a7b-9f03-18bb-64cba6e030a5'; //iiwari
    
    
    useEffect(()=>{
        return;
        setTimeout(()=>{
            let frame = document.getElementById("iiwariframe");
           console.log(frame);
        }, 5000);
    },[]);
    
    return(
        <div width="100%">
            <iframe
                id='iiwariframe' 
                src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                //sandbox="allow-top-navigation"
                style={{
                    marginLeft: "2.5%",
                    marginTop: "2.5%",
                    alignSelf: "center",
                    height: "80vh",
                    //margin: "3vh",
                    //width: "95%",
                    width: "1200px",
    //                    minHeight: "",
                    marginRight:"10px", 
                    marginTop:"10px",
                    //border:"none",
                }}
                >
            </iframe>
            {/*<LocationEventRunner></LocationEventRunner>*/}
            <div id="newtest"></div>
        </div>
    );

}


const LocationEventRunner=()=>{

    let ii;

    useEffect(()=>{
        console.log("setup");
        ii = new IIWARI_CONNECTOR(); 
        setupIiwari();

    },[]);

    async function setupIiwari() {
        
        let loggin;
        await ii.postAPIData('users/login', {}).then((res)=>{ 
          loggin = res 
        }).catch(e=>{reportError(e)});        
        
        console.log({loggin});

        const token = {'token': loggin.data.token};
        if ((loggin.data==null) || (loggin.data.user==null)) return;
    
        let sites;
        await ii.getAPIData('sites', token).then((res)=>{ sites = res }).catch(e=>reportError(e));
        if ((sites==null) || (sites.data==null)) return;
        console.log("SSSSSSSSS", sites.data);

        

        let siteStream = ii.getAPIData("sites/018290c0-4a7b-9f03-18bb-64cba6e030a5/locations/stream",token)
        .then((res)=>{ 
            console.log("Stream");
            console.log(res); 
        })
        .catch(e=>reportError(e));


/*
        let siteStream = await ii.getAPIData("sites/018290c0-4a7b-9f03-18bb-64cba6e030a5/events/stream", token)
        .then((res)=>{ 
            console.log("Stream");
            console.log(res); 
        })
        .catch(e=>reportError(e));
*/


    } 

    function reportError(err){ console.log("ERRRR", err); }

    /*
    var locationDB = axios.create({
        baseURL: 'https://dash.iiwari.cloud/api/v1/',
        timeout: 1000,
        //headers: {'X-Custom-Header': 'foobar'}
    });

    function login(){
        //locationDB.
    }

    useEffect(()=>{
        
        login();
        

    },[]);
    */

    return(
    <>
        
    </>
    );

}




export default LocationPage;