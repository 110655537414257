import React, {useState, useEffect} from "react";
import { firebaseOnValue } from "../../db/firebase";

const Sense=()=>{

    const siteRef = "vayyarmonitor_site/config/Vayyar/presence";
    
    const [data, setData] = useState();
    const [dataObjectUpdated, setDataObjectUpdated] = useState();
    const objData = {};

    function errorReceived(error){ console.log(error); }

    const isValid=function(val){
        if ((val===undefined)||(val===null)||(val==="")) return false;
        return true; 
    }

    useEffect(()=>{
        if (isValid(siteRef)===false) return;
        firebaseOnValue(`${siteRef}`, setData, errorReceived);
    },[]);

    return (
        <div>
            <div>3</div>
            <div>
            {
                data.map(d=>{
                return(
                    <div className="roomRow">
                        <div>{d.room}</div>
                        <div>{d.residentStatus}</div>
                        <div>{d.unitStatus}</div>
                    </div>
                );
            })}
            </div>
        </div>
    );
    
}


const dd= [
    {
        room: "Room 1",
        unitStatus: "Disconnected",
        residentStatus: "Chair"
    },
    {
        room: "Room 2",
        unitStatus: "Disconnected",
        residentStatus: "In bed"
    },
    {
        room: "Room 3",
        unitStatus: "Disconnected",
        residentStatus: "No presence detected."
    },
    {
        room: "Room 4",
        unitStatus: "Connected",
        residentStatus: "Multiple people detected."
    },
    {
        room: "Room 5",
        unitStatus: "Disconnected",
        residentStatus: "Chair"
    },
    {
        room: "Room 6",
        unitStatus: "Disconnected",
        residentStatus: "Chair"
    },
    {
        room: "Room 7",
        unitStatus: "Disconnected",
        residentStatus: "Chair"
    },

];

export default Sense;