import React, {useState, useEffect} from "react";
import { firebaseOnValue } from "../../db/firebase";
import './onboarding.css';

const MonitoringReport=()=>{

    const site = "adctestmonitoring";
    
    return (
        <div className="panel_lists_container">
            <Site siteRef={site} />
        </div>
    );
}


const Site=({siteRef})=>{

    const [data, setData] = useState([]);
    
    function errorReceived(error){ console.log(error); }

    const isValid=function(val){
        if ((val===undefined)||(val===null)||(val==="")) return false;
        return true; 
    }

    useEffect(()=>{
        if (isValid(siteRef)===false) return;
        firebaseOnValue(`${siteRef}`, setData, errorReceived);
    },[]);

    return (
        <div>
            <div>{data.length}</div>
            <div>{
                data.map(d=>{
                return(
                    <div>
                        <div>{d.callType}</div>
                        <div>{d.room}</div>
                        <div>{d.start}</div>
                    </div>
                );
            })}
            </div>
        </div>
    );
    
}

export default MonitoringReport;