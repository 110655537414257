import React, {useState, useEffect, useRef, useContext} from 'react';
import DropSelect from '../../components/common/DropSelect/DropSelect';
import DropDownMultiSelect from '../../components/DropDown_MultiSelect';
import './Dashboard.css';
import LiveCallController from './LiveCall/LiveCallController';
import NewLiveCallController from './LiveCall/NewLiveCallController';
import 'bootstrap/dist/css/bootstrap.min.css'
import PrintOptions from '../../components/PrintOptions';
import CompletedCalls from './CompletedCalls';
import getLocalSetting from '../../components/get_local_setting';   
import AuthContext from '../../AuthContextProvider';
//import Sense from './LiveCall/Sense';
//import MaintenanceDashboard from './Maintenance';

const ArqDashboard = ({USER, permissions}) => {
    const ctx = useContext(AuthContext);

    function getPanelOptions(){
        let sites = JSON.parse(getLocalSetting('sites'));
        let hOptions = [...sites];
        
        if ((ctx.sitePanels) && (ctx.sitePanels.length > 0)) {
            ctx.sitePanels.map(p=>{
                if (p.visible === 1) hOptions.push({"label": p.name, "value": p.panel_ref});
            });
        }
        return hOptions;
    }
    

    const [homeSelections, setHomeSelections] = useState(getPanelOptions());
    const [homeSelection, setHomeSelection] = useState((getLocalSetting('sites')!=null) ? JSON.parse(getLocalSetting('sites'))[0].label : "");
    const [dateSelection, setDateSelection] = useState("Today");
    const [homeOptions, setHomeOptions] = useState(getPanelOptions());
    const [CompleteCallsPage, setCompleteCallsPage] = useState(<div></div>);

    const [printValues, setPrintValues] = useState({
        print: {},
        excel: {},
        pdf: {}
    });




//    const HomeSelection = (props) => { return <DropSelect options={homeOptions} selectedVal={homeSelection} changeHandler={siteChanged} /> }
    const HomeSelection = (props) => { return <DropDownMultiSelect 
        options={homeOptions} 
        selections={homeSelections} 
        filterCall={setHomeSelections} 
        /> 
    }

    const DateSelection = (props) => {   

        const options = getDateSelectionList();
        if ((dateSelection === "") && (options.length>0)) {dateChanged(options[0].label)}
        
        return <DropSelect options={options} selectedVal={dateSelection} changeHandler={dateChanged} />
    }

    function dateChanged(dateVal) { setDateSelection(dateVal); }

    function printCallBack(print, excel, pdf){
        
        if (
            (printValues.print !== print) ||
            (printValues.excel !== excel) ||
            (printValues.pdf !== pdf)
        ) 
        {
            setPrintValues({
                print: print,
                excel: excel,
                pdf: pdf
            });
        }
        
    }

    const updateCompletedCalls=()=>{
        setCompleteCallsPage(
            <CompletedCalls
                dateFormat={getSiteDateFormat(homeSelection)}
                USER={USER}
                homeSelection={homeSelections}
                dateSelection={dateSelection}
                printCallBack={printCallBack}
            />
        );
    }

    useEffect(()=>{ updateCompletedCalls(); },[homeSelections, dateSelection]);
    

    function getSiteDateFormat(siteCode){
        try { return ctx.sites.filter(s=>(s.name===siteCode))[0].dateformat; }
        catch(e){ return null; }
    }


    let liveLocation = "";
    const tmpHome = JSON.parse(getLocalSetting('sites'))[0].value
    
    if (tmpHome.indexOf('_site/calls') > -1) {
        liveLocation = liveLocation.toLowerCase();
        liveLocation = tmpHome.replace('_site/calls', 'PhoneLiveCalls');
    }
    
    return (

        <div id="AIDA_NAV_MAIN">

        <NavPane>

        <div>

            <div><p>Home</p><HomeSelection /></div>

            <div><br /><p>Set Time Period</p><DateSelection /></div>

        </div>

        <p>&nbsp;</p>        

        <PrintOptions
            wordOBJ={printValues.print}
            excelOBJ={printValues.excel}
            pdfOBJ={printValues.pdf}
        />

        </NavPane>


    <div className='dashboard_grid'>

        {CompleteCallsPage}
        <div>
            {
            (liveLocation==="")
            ?   <LiveCallController homeSelection={homeSelection} dateSelection={dateSelection} sitePanels={(ctx.sitePanels ? ctx.sitePanels : [])} />
            :   <NewLiveCallController homeSelection={liveLocation} dateSelection={dateSelection} sitePanels={(ctx.sitePanels ? ctx.sitePanels : [])} />
            }    
            {/*
            <Sense />
        */}
        </div>
        
    </div>
    
    </div>

    );

}


const NavPane = (props) => {
    //AIDA_NAV
    const [show, setShow]=useState(true);
    const className = ((show) ? "AIDA_NAV" : "nav-collapse");
   
    return (
        <div 
            id="AIDA_NAV" 
            className={className}
        >
            {/*
            <div onClick={()=>{setShow(!show)}}>
                {(show) ? "Hide" : "Show"}
            </div>
            */}

            {(show===true) && props.children}
        </div>
    );
}


const getDateSelectionList = (props) => {   
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const options = [];
    
    let addDate = new Date();
    let dayBack1 = new Date();
    let dayBack2 = new Date();
    let dayBack3 = new Date();
    
    dayBack1.setDate(dayBack1.getDate() - 1);
    dayBack2.setDate(dayBack2.getDate() - 2);
    dayBack3.setDate(dayBack3.getDate() - 3);
    
    options.push({label:'Today', value: addDate});
    options.push({label:'Yesterday', value: dayBack1});
    options.push({label:days[dayBack2.getDay()], value: dayBack2});
    options.push({label:days[dayBack3.getDay()], value: dayBack3});
    
    return options;
}



export default ArqDashboard;