import { API_POST } from './API_POST';
//getting data from the API

const getDataFromSource=(reportType)=>{
    return new Promise(async (resolve, reject)=>{
   
      const postData = {
        firstName: 'andrewchapman@arquella.co.uk',
        lastName: 'Flintstone',
        repType: reportType,
        dataID: "",
        startDate: "",
        endDate: ""
      };

      API_POST('/trends/data', postData, resolve, reject);
        
    });
}

export default getDataFromSource;