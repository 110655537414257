import React, {useState, useEffect} from "react";
import { firebaseOnValue, firebasePush } from "../../db/firebase";
import './onboarding.css';
import { ShowFieldsFromObjArray } from "../../components/DataDisplay/DataDisplay";

const SiteCalls=()=>{

    const site = "adctest";
    const dateVal = "26042023";

    return (
        <div className="panel_lists_container">
            <Site siteRef={site + "_site/calls"} date={dateVal} />
            <Site siteRef={site + "CallRecords-Main Panel"} date={dateVal} promote={site + "_site/calls"} />
            <Site siteRef={site + "CallRecords-Panel 1"} date={dateVal} promote={site + "_site/calls"} />
            <Site siteRef={site + "CallRecords-Panel 2"} date={dateVal} promote={site + "_site/calls"} />
        </div>
    );
}


const Site=({siteRef, date, promote})=>{

    const [data, setData] = useState([]);
    
    function errorReceived(error){ console.log(error); }

    const isValid=function(val){
        if ((val===undefined)||(val===null)||(val==="")) return false;
        return true; 
    }

    useEffect(()=>{
        if (isValid(siteRef)===false) return;
        if (isValid(date)===false) return;
        firebaseOnValue(`${siteRef}/${date}/`, setData, errorReceived);
    },[]);

    function promoteRecord(rec){
        firebasePush(`${promote}/${date}/`, rec);
    }

    return (
        <div>
            <div>{data.length}</div>
            <div>{
                data.map(d=>{
                return(
                    <div>
                        <div>{d.callType}</div>
                        <div>{d.room}</div>
                        <div>{d.start}</div>
                        {(promote!=="")? <button onClick={()=>promoteRecord(d)}>Promote</button> : <div></div>}
                    </div>
                );
            })}
            </div>
        </div>
    );
    
}

export default SiteCalls;