import React, {useState, useRef, useEffect, componentRef, useContext} from 'react';
import AuthContext from '../../AuthContextProvider';
import firebase from 'firebase';
import getDataFromSource from '../../data/get_data_from_source';
import MonitorLivePhoneDB from './maintenance_components/MultiPanelDashboardPage';

const MultiPanelDashboardPage = (props) => {
    
    const ctx = useContext(AuthContext);

    return (
        <div id="AIDA_NAV_MAIN">
            <div className='flex flex-column'>
                <SitesDropDown />
            </div>
        </div>
    );
}


const SitesDropDown=()=> {
    
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState("arquellacare");

    useEffect(()=>{

        getDataFromSource("SitesList")
            .then((data)=>{ setSites(data); })

    },[])
    
    
    return(
        <div>
            <ArrayToSelect arr={sites} selectedValue={""} valueField={"dbcode"} labelField={"name"} onChange={setSelectedSite} />
            {(selectedSite!=="") ? <SiteMenu siteCode={selectedSite} /> : <div>&nbsp;</div>}
        </div>
    );
}


const SiteMenu=({siteCode})=>{

    const [page, setPage] = useState("Live Calls");
    const pageList = ["Live Calls", "Todays Calls", "Site", "Users", "Engineers", "Events", "", ""];
    
    return(
        <div>
            <div>{siteCode}</div>
            <div>{pageList.map(p=><button oncClick={()=>setPage(p)}>{p}</button>)}</div>
            {page==="Live Calls" && <MonitorLivePhoneDB siteName={siteCode} />}
            
        </div>
    );

}



const ArrayToSelect=({arr, valueField, labelField, selectedValue, onChange})=>{
    function changeEvent(event){ onChange(event.target.value); }
    return (
    <select onChange={changeEvent}>
        <option value="">Please select a site</option>
        {arr.map((s)=>{return (<option selected={s[valueField]==selectedValue} value={s[valueField]}>{s[labelField]}</option>)})}
    </select>);
}


export default MultiPanelDashboardPage;