export function getDBSetting(){
    
    try {
        const val = localStorage.getItem("dbVal");
        console.log("localDBVAL", val);
        if (val===null) { 
            return 1; 
        }
        return parseInt(val);
    }
    catch(e){ 
        console.log("we have an error getting the db value");
        console.log(e);
        return 1; 
    }
}