import React, {useState} from "react";
import NourishMenu from "./nourish/nourish";
import Iiwari_Menu from "./Move/iiwari";


const IntegrationsMenu=({SEC})=>{
    
    const integrationOptions = [
        "Nourish", "PCS", "Move", ""
    ];

    const [integrationsList, setIntegrationsList] = useState({
        "nourish": {
            clientToken: ""
        }
    });

    function addIntegration(integrationName){

        if (integrationName == "nourish") {
            
        }
        
        if (integrationName == "nourish") {

        }

    }

    return <Iiwari_Menu SEC={SEC} />;
    return <NourishMenu SEC={SEC} />;

    return(
        <div className="flex-column">
            <div>Integrations</div>
            {
            integrationOptions.map((i)=>{
                return (
                    <button onClick={()=>addIntegration(i.toLower())}>Add {i}</button>
                );
            })
            }
        </div>
    );
}

export default IntegrationsMenu;