import React, { useState } from "react";
import './DropSelect.css';
import styled from "styled-components";


export default function DropSelect (props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selectedVal);
    const options = props.options;
    const toggling = () => setIsOpen(!isOpen);
    const changeHandlerFunction = props.changeHandler;

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
        if (props.changeHandler) props.changeHandler(value);  
      };
    
    return(
   
        <DropDownContainer>
          <DropDownHeader onClick={toggling}>
            {selectedOption}
          </DropDownHeader>
          {isOpen && (
            <DropDownListContainer>
              <DropDownList>
                {options.map(option => (
                  <ListItem onClick={onOptionClicked(option.label)} key={Math.random()}>
                  {
                  (option.label == selectedOption) 
                    ? <input type="checkbox" checked readOnly />
                    : <input type="checkbox" readOnly /> 
                  }
                    &nbsp;
                    {option.label}
                  </ListItem>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
   
        );
    
}

const DropDownContainer = styled("div")`
  width:100%
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
    margin-bottom: 0.8em;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: 1px #4cc1bd solid;
    height: 23px;
    color: #4cc1bd;
    padding-left: 6px;
    background-color: white;
`;
//width: 166px;
    

const DropDownListContainer = styled("div")`
  z-index: 900;
  float: none;
  color: #4cc1bd;
  text-decoration: none;
  text-align: left;
  background-color:white;
  min-width: 160px;
  z-index: 900;
  position: absolute;
     
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #4cc1bd;
  box-sizing: border-box;
  color: #4cc1bd;
  font-size: 1.0rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }

`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;


