import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './firebase/provider/AuthProvider';
import {BrowserRouter} from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import './globals';
import packageJson from '../package.json';
import './custom.scss';
import {encrypt} from './encrypt/encryptionHandler';
import reportWebVitals from './reportWebVitals';

let vNum = packageJson.version;
const vINum = parseInt(vNum.replaceAll(".", ""));

global.appVersion = vNum;
global.appVersionNumber = vINum;

axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.post['Access-Control-Allow-Origin'] = process.env.REACT_APP_ROOT;
axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8; text/plain; */*';
axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

const dev = true;

axios.interceptors.request.use(request => {

  if (dev) console.log("API REQUEST:::", request);
  let data_to_encrypt = {...request.data, "vNum": vNum};
  data_to_encrypt = JSON.stringify(data_to_encrypt);
  let encrypted_data = encrypt(data_to_encrypt);
  request.data = encrypted_data;
  return request;
}, error => {
  if (dev) console.log("API REQUEST ERROR:::");
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  if (dev) console.log("API RESPONSE:::", response);
  return response;
}, error => { 
  if (dev) console.log("API RESPONSE ERROR:::", error);
  return Promise.reject(error);
});


ReactDOM.render(
<BrowserRouter>
<AuthProvider>
    <App />
</AuthProvider>
</BrowserRouter>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

//serviceWorker.unregister();
serviceWorker.register();
